<template>
  <div class="project-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="maintenance/repair-orders/customer"
    >
      <el-button
        slot="btn"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        @click="exportExcelData">
        {{$l("common.search", "导出")}}
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('project.name','维保班组')" prop="teamName">
            <vm-autocomplete
              :filter.sync="filter.teamName"

              url="organization/team/list"></vm-autocomplete>
          </vm-search>
          <vm-search label="队长姓名">
            <el-input v-model.trim="filter.teamLeaderName" clearable></el-input>
          </vm-search>
          <vm-search label="队长电话">
            <el-input v-model.trim="filter.teamLeaderPhone" clearable></el-input>
          </vm-search>
          <vm-search label="工单编号">
            <el-input v-model.trim="filter.orderCode" clearable></el-input>
          </vm-search>
          <vm-search label="维修类型">
            <vm-dict-select v-model="filter.typeDesc" type="repairType"></vm-dict-select>
          </vm-search>

          <vm-search label="报修时间">
            <el-date-picker
              v-model.trim="filter.createTime"
              style="width: 193px"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              clearable></el-date-picker>
          </vm-search>
          <vm-search label="使用单位">
            <vm-autocomplete
              :filter.sync="filter.usingCompanyName"
              url="customer-relationship/customer-company/list"></vm-autocomplete>
          </vm-search>
          <vm-search label="楼盘">
            <vm-autocomplete
              :filter.sync="filter.realEstateName"
              url="real-estate/list"></vm-autocomplete>
          </vm-search>
          <vm-search label="楼宇">
            <vm-autocomplete
              :filter.sync="filter.buildingName"
              url="real-estate/building/list"></vm-autocomplete>
          </vm-search>
          <vm-search label="内部编号">
            <el-input v-model.trim="filter.assigneeName" clearable></el-input>
          </vm-search>
          <vm-search label="品牌">
            <vm-autocomplete
              :filter.sync="filter.brandName"
              url="basic/elevator-brand/list"></vm-autocomplete>
          </vm-search>
          <vm-search label="型号">
            <vm-autocomplete
              :filter.sync="filter.modelName"
              url="basic/elevator-model/list"></vm-autocomplete>
          </vm-search>
          <vm-search label="注册代码">
            <el-input v-model.trim="filter.regCode" clearable></el-input>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="teamName" :label="$l('project.name','维保班组')">
        <el-table-column prop="teamLeaderName" label="队长">
          <template slot-scope="scope">
            <div>
              {{scope.row.teamName}}
            </div>
            <div> {{scope.row.teamLeaderName}}/{{scope.row.teamLeaderPhone}}</div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column prop="assigneeName" label="工单责任人姓名/电话">
        <template slot-scope="scope">
          {{scope.row.assigneeName}}/{{scope.row.assigneePhone}}
        </template>
      </el-table-column>
      <el-table-column prop="orderCode" label="工单编号"></el-table-column>
      <el-table-column prop="orderStatusDesc" label="工单状态"></el-table-column>
      <el-table-column prop="typeDesc" label="维修类型"></el-table-column>
      <el-table-column prop="createTime" label="报修时间"></el-table-column>
      <el-table-column prop="customerCompanyName" label="使用单位"></el-table-column>
      <el-table-column prop="realEstateName" label="楼盘/楼宇">
        <template slot-scope="scope">
          {{scope.row.realEstateName}}/{{scope.row.buildingName}}
        </template>
      </el-table-column>
      <el-table-column prop="elevatorName" label="内部编号"></el-table-column>
      <el-table-column prop="regCode" label="注册代码"></el-table-column>
      <el-table-column prop="createTime" label="品牌型号">
        <template slot-scope="scope">
          {{scope.row.brandName}}/{{scope.row.modelName}}
        </template>
      </el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
        <template slot-scope="scope">
          <el-button type="primary" @click="$refs.editPage.open(scope.row)">{{$l("common.detail", "详情")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>

    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
  </div>
</template>
<script>
  import EditPage from "./RepairDetailNew.vue";
  import VmAutocomplete from "@/components/VmAutocomplete";
  import {export2Excel} from "@/util";
  import moment from "moment/moment";

  export default {
    components: {VmAutocomplete, EditPage},
    data() {
      return {
        filter: {
          name: "",
          customerName: "",
          wbManagerName: "",
          orderStatus: "",
          orderColumnName:"createTime",
        },
        statistics:{},
      };
    },
    mounted() {
      this.getList(1);

    },
    methods: {
      exportExcelData() {
        const title={
          teamName:"维保班组",
          teamLeaderName:"队长",
          teamLeaderPhone:"联系电话",
          assigneeName:"工单责任人",
          assigneePhone:"电话",
          orderCode:"工单编号",
          orderStatusDesc:"工单状态",
          typeDesc:"维修类型",
          createTime:"报修时间",
          assignedTime:"认领时间",
          arrivedTime:"打卡时间",
          completedTime:"完成时间",
          customerCompanyName:"使用单位",
          realEstateName:"楼盘",
          buildingName:"楼宇",
          elevatorName:"内部编号",
          regCode:"注册代码",
          brandName:"品牌",
          modelName:"型号",
          modelDesc:"设备型号",
          faultCategoryCode:"故障代码",
          faultReason:"故障名称",
        };
        this.loading =true;
        this.$http.get("maintenance/repair-orders/customer",{...this.filter,pageSize:300000}).then(res=>{
          const arr = res.records;
          //     .map(item=>{
          //   if (item.faultCode && item.faultCode.indexOf("E")===-1) {
          //     item.faultCode ="客服报修："+item.faultCode;
          //     item.faultName ="客服报修："+item.faultName;
          //     return item;
          //   }else{
          //     return item;
          //   }
          // });
          console.log(arr,227);
          this.loading =false;
          export2Excel(title,arr,`维修工单-全部-${res.total}条-${moment(new Date()).format("YYYYMMDDhhmmss")}`);
        });
      },

      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.projectName}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`/maintenance/maintain-plan/${row.id}`).then(() => {
            this.getList(-1);
            this.$message.success(row.projectName + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
